@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.breadcrumbs {
  padding: 40px 0 32px;
  overflow: auto;
  position: relative;
  user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include desktopStyle {
    padding-top: get-vw(40px);
    padding-bottom: get-vw(32px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &:global {
    & + div {
      padding-top: 0;
    }
  }

  &_item {
    position: relative;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      transform: rotate(45deg);
      border-bottom: 1.5px solid var(--gray-light);
      border-left: 1.5px solid var(--gray-light);
      margin: 0 16px;
      @include desktopStyle {
        margin-left: get-vw(16px);
        margin-right: get-vw(16px);
        width: get-vw(7px);
        height: get-vw(7px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-left: 12px;
        margin-right: 12px;
      }
    }
    &:first-child {
      &::before {
        content: none;
      }
    }
  }

  &_link {
    color: var(--gray-light);
    font-size: 1.4rem;
    white-space: nowrap;
    text-decoration: none;
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.3rem;
    }
    &:not(.b-last):hover {
      color: var(--blue);
    }
  }
}
